import spinner from '#eproc/spinner.js';

function targetToJqueryEl(target) {
    if (typeof target === 'string') {
        if (target.charAt(0) === '#') {
            target = $(target)
        } else {
            target = $('#' + target)
        }
    }

    return target
}

function submit(opts) {
    let form = $('#' + opts.formId);
    form.on('submit', function(event) {
        event.preventDefault();
        if (form[0].checkValidity()) {
            let $spinner = spinner.createGlobalOverlay({message: 'Processando...'});
            axios({
                method: 'post',
                url: form.attr('action'),
                data: new FormData(form[0]),
            })
                .then(response => {
                    opts.submitSucessoCallback !== null ? opts.submitSucessoCallback(response) : submitSucessoCallback(
                        response);
                })
                .catch(error => {
                    opts.submitErroCallback !== null ? opts.submitErroCallback(error) : submitErroCallback(error);
                })
                .finally(() => $spinner.remove());
        }
    });
}

const delay = '5000';

function submitSucessoCallback(response) {
    eproc.toast.success({
        'text': response.data,
        'data': {'delay': delay},
    });
}

function submitErroCallback(error) {
    let mensagens = [];
    $.each(error.response.data, function(idCampo, msgsCampo) {
        if (idCampo === 'geral') {
            $.each(msgsCampo, function(idGeral, msgGeral) {
                mensagens.push(msgGeral);
            });
        } else {
            mensagens = $.merge(mensagens, msgsCampo);
        }
    });
    let opts = {
        'text': mensagens.join('<br>'),
        'data': {'delay': delay},
    };
    error.response.status === 501
        ? eproc.toast.warning(opts)
        : eproc.toast.error(opts);
}

/**
 *
 * @param targetEl
 * @param url
 * @param data
 * @returns {*}
 */
function load(targetEl, url, data) {
    targetEl = targetToJqueryEl(targetEl)
    targetEl.html('')
    const $spinner = spinner.createElement({size: 'md'})
    $spinner.classList.add('mt-5', 'mb-5')

    const $jqSpinner = $($spinner)
    targetEl.append($jqSpinner)

    return targetEl.load(url, data, () => $spinner.remove())
}

export default {
    load: load,
    submit: submit,
    submitSucessoCallback: submitSucessoCallback,
    submitErroCallback: submitErroCallback,
}