/**
 *
 * @param opts
 * @returns {HTMLDivElement}
 */
function createElement(opts) {
    const additionalClass = 'loader-' + opts.size
    const mensagemFeedback = opts.msg || 'Carregando...';

    const $wrapper = document.createElement('div')
    $wrapper.className = `text-center d-flex align-items-center justify-content-center`
    $wrapper.innerHTML = `        
        <div class="d-flex flex-column align-items-center justify-content-center">
            <div class="eproc-loader ${additionalClass}" role="status">
                <div class="eproc-loader-bubble bubble-1"></div>
                <div class="eproc-loader-bubble bubble-2"></div>
                <div class="eproc-loader-bubble bubble-3"></div>
            </div>
            <label class="mt-3 text-primary-new">${mensagemFeedback}</label>
        </div>
    `
    return $wrapper
}

/**
 *
 * @param opts
 * @returns {{el: HTMLDivElement, remove: (function(): HTMLDivElement)}}
 */
function createGlobalOverlay(opts) {
    if (opts === undefined) {
        opts = {}
    }
    opts.size = 'lg'

    const $spinner = createElement(opts)

    $spinner.style['background'] = 'rgba(255,255,255,0.5)'
    $spinner.style['top'] = 0
    $spinner.style['z-index'] = 2000
    $spinner.style['position'] = 'fixed'
    $spinner.style['width'] = '100%'
    $spinner.style['height'] = '100%'
    document.body.appendChild($spinner)
    return {
        el: $spinner,
        remove: () => document.body.removeChild($spinner),
    }
}


export default {
    createGlobalOverlay: createGlobalOverlay,
    createElement: createElement,
}